import Image from "../Image";
// @mui
import { Box, Typography, Stack } from "@mui/material";
// assets
import { UploadIllustration } from "../../assets";

// ----------------------------------------------------------------------

export default function BlockContent({ preview, fileName, label }) {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: "column", md: "row" }}
      sx={{ width: 1, textAlign: { xs: "center", md: "left" } }}
    >
      {preview ? (
        // <Image
        //   alt="file preview"
        //   src={preview}
        //   sx={{
        //     // top: 8,
        //     // left: 8,
        //     borderRadius: 1,
        //     // position: "absolute",
        //     width: "145px",
        //   }}
        // />
        preview
      ) : (
        <UploadIllustration sx={{ width: 220 }} />
      )}

      <Box sx={{ p: 3 }}>
        <Typography gutterBottom variant="h5">
          {fileName ? fileName : label ? label : "Drop or Select file"}
        </Typography>

        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          Drop files here or click&nbsp;
          <Typography
            variant="body2"
            component="span"
            sx={{ color: "primary.main", textDecoration: "underline" }}
          >
            browse
          </Typography>
          &nbsp;thorough your machine
        </Typography>
      </Box>
    </Stack>
  );
}
