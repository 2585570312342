import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
// config
import { PATH_AFTER_LOGIN } from "../config";
// components
import LoadingScreen from "../components/LoadingScreen";

// Organisation Data Providers
import { OrgUnitProvider } from "../contexts/organisation/OrgUnitContext";
import { UsersProvider } from "src/contexts/organisation/users/UsersContext";
import { CustomFieldsProvider } from "src/contexts/organisation/customFields/CustomFieldsContext";
import { CustomFieldValuesProvider } from "src/contexts/organisation/customFields/CustomFieldValuesContext";

// Social post data providers
import { SocialPostProvider } from "src/contexts/socialPost/SocialPostContext";
import { LinkedInContextProvider } from "src/contexts/socialPost/LinkedInContext";

// Chat app data providers
import { ChatReportContextProvider } from "src/contexts/chatApp/ChatReportContext";
import { ChatSettingsProvider } from "src/contexts/chatApp/ChatSettingsContext";
import { ChatTokenProvider } from "src/contexts/chatApp/ChatTokenContext";

// Gupshup and Whatsapp data providers
import { GupshupSettingsProvider } from "src/contexts/whatsApp/WhatsAppSettingsContext";
import { WhatsAppTemplateProvider } from "src/contexts/whatsApp/WhatsAppTemplateContext";
import { DialogflowContextProvider } from "src/contexts/dialogflow/DialogflowContext";
import { BroadcastJourneyProvider } from "src/contexts/whatsApp/BroadcastJourneyContext";
import { CreateTemplateProvider } from "src/contexts/whatsApp/CreateTemplateContext";

// GMB and Business messages data providers
import { GmbLocationProvider } from "src/contexts/googleMyBusiness/GmbLocationContext";
import { GmbLocAccProvider } from "src/contexts/googleMyBusiness/GmbLocAccContext";
import { GeoGridContextProvider } from "src/contexts/googleMyBusiness/GeoGridContext";
import { ReviewLocationsContextProvider } from "src/contexts/googleMyBusiness/reviews/ReviewLocationsContext";
import { LocationReviewsContextProvider } from "src/contexts/googleMyBusiness/reviews/LocationReviewsContext";
import { CannedResponsesContextProvider } from "src/contexts/googleMyBusiness/reviews/CannedResponsesContext";
import { UpdateProfileProvider } from "src/contexts/googleMyBusiness/businessProfile/UpdateProfile";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: "login-unprotected", element: <Login /> },
      ],
    },
    {
      path: "/",
      element: <Navigate to="/dashboard/home" replace />,
    },
    {
      path: "/dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "home", element: <Home /> },
        {
          path: "organisation",
          children: [
            {
              element: <OrgUnitProvider />,
              children: [
                { path: "units", element: <OrgUnit /> },
                {
                  element: <UsersProvider />,
                  path: "users",
                  children: [
                    {
                      path: "list",
                      element: <UserList />,
                    },
                    {
                      path: "create-user",
                      element: <UserCreate />,
                    },
                    {
                      path: ":name/edit",
                      element: <UserCreate />,
                    },
                  ],
                },
              ],
            },
            {
              element: <CustomFieldsProvider />,
              children: [
                {
                  element: <CustomFieldValuesProvider />,
                  children: [
                    { path: "custom-fields", element: <CustomFields /> },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "social-post",
          children: [
            {
              element: <SocialPostProvider />,
              children: [
                {
                  element: <LinkedInContextProvider />,
                  children: [
                    {
                      element: <GmbLocAccProvider />,
                      children: [
                        {
                          path: "new-post",
                          element: <NewPost />,
                        },
                      ],
                    },
                    {
                      path: "settings",
                      element: <Settings />,
                    },
                    {
                      path: "settings/:social",
                      element: <Settings />,
                    },
                  ],
                },
              ],
            },
            {
              path: "all-posts",
              element: <AllPosts />,
            },
            {
              path: "analytics",
              element: <Analytics />,
            },
            {
              path: "analytics/:postName/:id",
              element: <IndividualAnalytics />,
            },
          ],
        },
        {
          element: <ChatTokenProvider />,
          children: [
            {
              path: "chat-app",
              children: [
                { path: "settings", element: <ChatAppSettings /> },
                {
                  element: <ChatReportContextProvider />,
                  children: [{ path: "report", element: <ChatReport /> }],
                },
              ],
            },
            {
              path: "google-my-business",
              element: <ChatSettingsProvider />,
              children: [
                {
                  path: "settings",
                  children: [
                    {
                      path: "general",
                      element: <GmbSettings />,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          element: <GupshupSettingsProvider />,
          children: [
            {
              element: <WhatsAppTemplateProvider />,
              children: [
                {
                  path: "whatsapp",
                  children: [
                    {
                      element: <ChatTokenProvider />,
                      children: [
                        {
                          path: "whatsapp-settings",
                          element: <GupshupSettings />,
                        },
                      ],
                    },
                    {
                      path: "whatsapp-templates",
                      element: <GupshupTemplates />,
                    },
                    {
                      element: <CreateTemplateProvider />,
                      children: [
                        {
                          path: "whatsapp-templates/create-template",
                          element: <CreateTemplate />,
                        },
                      ],
                    },

                    {
                      path: "broadcast-message",
                      element: <WhatsAppBroadcastMessage />,
                    },
                    {
                      path: "broadcast-list",
                      element: <BroadcastScheduleList />,
                    },
                    {
                      element: <BroadcastJourneyProvider />,
                      children: [
                        {
                          path: "broadcast-journey",
                          children: [
                            {
                              element: <BroadcastJourneyList />,
                              index: true,
                            },
                            {
                              path: "create-journey",
                              element: <NewEditJourney />,
                            },
                            {
                              path: "edit/:id/:name",
                              element: <NewEditJourney />,
                            },
                            {
                              path: "initiate/:id/:name",
                              element: <InitiateJourney />,
                            },
                            {
                              path: "initiate/history/:id/:name",
                              element: <JourneyHistory />,
                            },
                            {
                              path: "new-broadcast/:id/:journeyName",
                              element: <NewEditBroadcast />,
                            },
                            {
                              path: "broadcast/:id/:journeyName/:broadcastId/:broadcastName",
                              element: <NewEditBroadcast />,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      path: "unsubscribers-list",
                      element: <UnsubscribersList />,
                    },
                    {
                      element: <DialogflowContextProvider />,
                      children: [
                        {
                          path: "dialogflow",
                          children: [
                            {
                              path: "analytics",
                              element: <DialogflowAnalytics />,
                            },
                            {
                              path: "manage-intents",
                              element: <DialogflowIntents />,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "google-my-business",
          children: [
            {
              path: "reviews",
              children: [
                {
                  element: <ReviewLocationsContextProvider />,
                  children: [
                    {
                      element: <Reviews />,
                      index: true,
                    },
                  ],
                },
                {
                  element: <LocationReviewsContextProvider />,
                  children: [
                    {
                      path: ":locId/:locName",
                      element: <LocationReviews />,
                    },
                    {
                      path: ":locId/:locName/analytics",
                      element: <LocationReviewsAnalytics />,
                    },
                  ],
                },
                {
                  element: <CannedResponsesContextProvider />,
                  children: [
                    {
                      path: "canned-responses",
                      element: <CannedResponses />,
                    },
                  ],
                },
              ],
            },
            {
              path: "business-messages-analytics",
              children: [
                {
                  element: <BusinessMsgsReport />,
                  index: true,
                },
              ],
            },
            {
              element: <GeoGridContextProvider />,
              children: [
                {
                  path: "geo-grid",
                  element: <GeoGrid />,
                },
                {
                  path: "geo-grid",
                  children: [
                    {
                      element: <GmbLocAccProvider />,
                      children: [
                        {
                          path: "new-report-schedule",
                          element: <ScheduleReport />,
                        },
                      ],
                    },
                    {
                      path: ":id",
                      element: <GeoGridReportDetails />,
                    },
                    {
                      path: ":id/:locName/:locId/:repId/:type/:keyId/:key",
                      element: <LocationReport />,
                    },
                  ],
                },
              ],
            },
            {
              path: "settings",
              element: <ChatSettingsProvider />,
              children: [
                {
                  path: "general",
                  element: <GmbSettings />,
                },
              ],
            },
            {
              element: <OrgUnitProvider />,
              children: [
                {
                  element: <GmbLocAccProvider />,
                  children: [
                    {
                      path: "settings",
                      children: [
                        {
                          path: "manage-locations",
                          element: <ManageLocations />,
                        },
                      ],
                    },
                  ],
                },
                {
                  element: <UsersProvider />,
                  children: [
                    {
                      element: <ChatSettingsProvider />,
                      children: [
                        {
                          element: <GmbLocationProvider />,
                          children: [
                            { path: "locations", element: <Locations /> },
                            { path: "add-location", element: <AddLocation /> },
                            { path: ":name/edit", element: <AddLocation /> },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              element: <GmbLocAccProvider />,
              children: [
                {
                  element: <UpdateProfileProvider />,
                  children: [
                    {
                      path: "profile-info",
                      children: [
                        { element: <UpdateProfile />, index: true },
                        {
                          path: "profile-update-history",
                          element: <ProfileUpdateHistory />,
                        },
                      ],
                    },
                  ],
                },
                {
                  path: "add-update",
                  children: [
                    {
                      element: <AddUpdate />,
                      index: true,
                    },
                    {
                      path: "update-history",
                      element: <UpdateHistory />,
                    },
                  ],
                },
                {
                  path: "upload-photos",
                  children: [
                    {
                      element: <UploadPhotos />,
                      index: true,
                    },
                    {
                      path: "upload-history",
                      element: <UploadHistory />,
                    },
                    {
                      path: "locations-list",
                      element: <LocationsList />,
                    },
                  ],
                },
                {
                  path: "services",
                  children: [
                    {
                      element: <Services />,
                      index: true,
                    },
                    {
                      path: "update-history",
                      element: <ServicesLogs />,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "assets-manager",
          children: [
            {
              path: "domain-setup",
              element: <DomainSetup />,
            },
            {
              path: "url-manager/manage-tags",
              element: <Tags />,
            },
            {
              path: "url-manager",
              element: <UrlManager />,
            },
            {
              path: "media-manager",
              element: <MediaManager />,
            },
          ],
        },
        {
          path: "profile",
          element: <Profile />,
        },
      ],
    },
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import("src/pages/auth/Login")));

// Dashboard

// General
const Home = Loadable(lazy(() => import("src/pages/Home")));
const Profile = Loadable(lazy(() => import("src/pages/Profile")));

// Organisation
const OrgUnit = Loadable(lazy(() => import("src/pages/organisation/OrgUnit")));
const UserList = Loadable(
  lazy(() => import("src/pages/organisation/users/UserList"))
);
const UserCreate = Loadable(
  lazy(() => import("src/pages/organisation/users/UserCreate"))
);
const CustomFields = Loadable(
  lazy(() => import("src/pages/organisation/CustomFields"))
);

// Social Posts
const NewPost = Loadable(lazy(() => import("src/pages/socialPost/NewPost")));
const AllPosts = Loadable(lazy(() => import("src/pages/socialPost/AllPosts")));
const Analytics = Loadable(
  lazy(() => import("src/pages/socialPost/Analytics"))
);
const IndividualAnalytics = Loadable(
  lazy(() => import("src/pages/socialPost/IndividualAnalytics"))
);
const Settings = Loadable(
  lazy(() => import("src/pages/socialPost/settings/Settings"))
);

// ChatApp
const ChatAppSettings = Loadable(
  lazy(() => import("src/pages/chatApp/ChatAppSettings"))
);
const ChatReport = Loadable(lazy(() => import("src/pages/chatApp/ChatReport")));

// WhatsApp
const GupshupSettings = Loadable(
  lazy(() => import("src/pages/whatsapp/Settings"))
);
const GupshupTemplates = Loadable(
  lazy(() => import("src/pages/whatsapp/Templates"))
);
const WhatsAppBroadcastMessage = Loadable(
  lazy(() => import("src/pages/whatsapp/MessageBroadcast"))
);
const BroadcastScheduleList = Loadable(
  lazy(() => import("src/pages/whatsapp/BroadcastsList"))
);
const BroadcastJourneyList = Loadable(
  lazy(() => import("src/pages/whatsapp/broadastJourney/BroadcastJourneyList"))
);
const NewEditJourney = Loadable(
  lazy(() => import("src/pages/whatsapp/broadastJourney/NewEditJourney"))
);
const InitiateJourney = Loadable(
  lazy(() => import("src/pages/whatsapp/broadastJourney/InitiateJourney"))
);
const JourneyHistory = Loadable(
  lazy(() => import("src/pages/whatsapp/broadastJourney/JourneyHistory"))
);
const NewEditBroadcast = Loadable(
  lazy(() => import("src/pages/whatsapp/broadastJourney/NewEditBroadcast"))
);
const DialogflowIntents = Loadable(
  lazy(() => import("src/pages/whatsapp/DialogflowIntents"))
);
const DialogflowAnalytics = Loadable(
  lazy(() => import("src/pages/whatsapp/DialogflowAnalytics"))
);
const UnsubscribersList = Loadable(
  lazy(() => import("src/pages/whatsapp/UnsubscribersList"))
);
const CreateTemplate = Loadable(
  lazy(() => import("src/pages/whatsapp/CreateTemplate"))
);

// Google My Business
const Locations = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/businessMessages/Locations"))
);
const AddLocation = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/businessMessages/AddLocation"))
);
const GmbSettings = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/settings/GmbSettings"))
);
const Reviews = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/reviews/ReviewLocations"))
);
const CannedResponses = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/reviews/CannedResponses"))
);
const LocationReviews = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/reviews/LocationReviews"))
);
const LocationReviewsAnalytics = Loadable(
  lazy(() =>
    import("src/pages/googleMyBusiness/reviews/LocationReviewsAnalytics")
  )
);
const ManageLocations = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/settings/ManageLocations"))
);
const BusinessMsgsReport = Loadable(
  lazy(() =>
    import("src/pages/googleMyBusiness/businessMessages/BusinessMsgsReport")
  )
);
const GeoGrid = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/groGrid/GeoGrid"))
);
const ScheduleReport = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/groGrid/ScheduleReport"))
);
const GeoGridReportDetails = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/groGrid/GeoGridReportDetails"))
);
const LocationReport = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/groGrid/LocationReport"))
);
const UpdateProfile = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/updateProfile/UpdateProfile"))
);
const ProfileUpdateHistory = Loadable(
  lazy(() =>
    import("src/pages/googleMyBusiness/updateProfile/ProfileUpdateHistory")
  )
);
const UploadPhotos = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/uploadMedia/UploadMedia"))
);
const UploadHistory = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/uploadMedia/UploadHistory"))
);
const AddUpdate = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/addUpdate/AddUpdate"))
);
const UpdateHistory = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/addUpdate/UpdateHistory"))
);
const LocationsList = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/uploadMedia/LocationsList"))
);
const Services = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/services/Services"))
);
const ServicesLogs = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/services/ServicesLogs"))
);

// Assets Manager
const DomainSetup = Loadable(
  lazy(() => import("src/pages/assetsManager/DomainSetup"))
);
const Tags = Loadable(lazy(() => import("src/pages/assetsManager/Tags")));
const UrlManager = Loadable(
  lazy(() => import("src/pages/assetsManager/UrlManager"))
);
const MediaManager = Loadable(
  lazy(() => import("src/pages/assetsManager/MediaManager"))
);

// Misc pages
const NotFound = Loadable(lazy(() => import("src/pages/Page404")));
