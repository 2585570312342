// react
import { createContext, useEffect, useReducer } from "react";
import { Outlet } from "react-router-dom";
// utils
import axios from "../../utils/axios";
import { isValidToken } from "../../utils/jwt";

// Initial State
const initialState = {
  type: null,
  details: null,
  wb_agent: null,
  broadcastLimit: null,
};

// Action handlers
const handlers = {
  INITIALIZE: (state, action) => {
    const { type, details, broadcastLimit } = action.payload;
    return {
      ...state,
      type,
      details,
      broadcastLimit,
    };
  },

  CREATE_WB_AGENT: (state, action) => {
    const { agent } = action.payload;
    return {
      ...state,
      wb_agent: agent,
    };
  },
};

// Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// Create Context
export const WhatsAppSettingsContext = createContext();

// Provider Component
export const GupshupSettingsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        const controller = new AbortController();
        if (accessToken && isValidToken(accessToken)) {
          const response = await axios.get(
            `/lms_campaign/api/whatsapp/settings`,
            { signal: controller.signal },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          const { details } = await response.data;
          // cancel the request
          controller.abort();
          dispatch({
            type: "INITIALIZE",
            payload: {
              type: details.type,
              details: JSON.parse(details.details),
              broadcastLimit: details.broadcast_limit,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              type: null,
              details: null,
              broadcastLimit: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            type: null,
            details: null,
            broadcastLimit: null,
          },
        });
      }
    };
    initialize();
  }, []);

  const addGupshupSettings = async (accSettings) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axios({
        method: "post",
        url: "/lms_campaign/api/whatsapp/settings",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          type: accSettings.type,
          app_name: accSettings.appName,
          api_key: accSettings.apiKey,
          sender_no: accSettings.senderNumber,
          broadcast_limit: accSettings.broadcast_limit,
        },
      });
      const { details } = await response.data;
      dispatch({
        type: "INITIALIZE",
        payload: {
          type: details.type,
          details: JSON.parse(details.details),
          broadcastLimit: details.broadcast_limit,
        },
      });
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const addWhatsappSettings = async (accSettings) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axios({
        method: "post",
        url: "/lms_campaign/api/whatsapp/settings",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          type: accSettings.type,
          access_token: accSettings.accessToken,
          wb_id: accSettings.wbId,
          broadcast_limit: accSettings.broadcast_limit,
          app_id: accSettings.appId,
        },
      });
      const { details } = await response.data;
      dispatch({
        type: "INITIALIZE",
        payload: {
          type: details.type,
          details: JSON.parse(details.details),
          broadcastLimit: details.broadcast_limit,
        },
      });
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const changeUserStatus = async (userID) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axios({
        method: "get",
        url: `/lms_user/api/user_status/${userID}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const { user } = await response.data;
      dispatch({
        type: "CHANGE_USER_STATUS",
        payload: {
          user,
        },
      });
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const getWhatsappAgent = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axios({
        method: "get",
        url: `/lms_chat/api/wb_chat_agent`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const { agent_id } = await response.data;
      return agent_id;
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const getChatAgents = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axios({
        method: "get",
        url: `/lms_chat/api/chat_agents`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const { agents } = await response.data;
      return agents;
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const createChatAgent = async (agentId, agentName, takeoverAgent) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axios({
        method: "post",
        url: "/lms_chat/api/wb_chat_agent",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          chat_agent_id: agentId,
          chat_agent_name: agentName,
          takeover_agent: takeoverAgent,
        },
      });
      const { agent } = await response.data;
      dispatch({
        type: "CREATE_WB_AGENT",
        payload: {
          agent,
        },
      });
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  return (
    <WhatsAppSettingsContext.Provider
      value={{
        type: state.type,
        details: state.details,
        wb_agent: state.wb_agent,
        broadcastLimit: state.broadcastLimit,
        addGupshupSettings,
        addWhatsappSettings,
        changeUserStatus,
        getWhatsappAgent,
        getChatAgents,
        createChatAgent,
      }}
    >
      <Outlet />
      {/* {children} */}
    </WhatsAppSettingsContext.Provider>
  );
};
