// react
import { createContext, useEffect, useReducer, useContext } from "react";
import { Outlet } from "react-router-dom";
// Date fns
import format from "date-fns/format";
// utils
import axios from "../../utils/axios";
import { isValidToken } from "../../utils/jwt";
// context
import { WhatsAppSettingsContext } from "src/contexts/whatsApp/WhatsAppSettingsContext";

// Initial State
const initialState = {
  templates: null,
};

// Action handlers
const handlers = {
  INITIALIZE: (state, action) => {
    const { templates } = action.payload;
    return {
      ...state,
      templates,
    };
  },
};

// Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// Create Context
export const WhatsAppTemplateContext = createContext();

// Provider Component
export const WhatsAppTemplateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { type } = useContext(WhatsAppSettingsContext);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        const controller = new AbortController();
        if (accessToken && isValidToken(accessToken) && type) {
          const response = await axios({
            method: "get",
            url: `/lms_campaign/api/whatsapp/${type}/templates`,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          const { templates } = await response.data;
          // cancel the request
          controller.abort();
          dispatch({
            type: "INITIALIZE",
            payload: {
              templates,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              templates: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            templates: null,
          },
        });
      }
    };
    initialize();
  }, [type]);

  const getTemplateHeads = async (templateId) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axios({
        method: "get",
        url: `/lms_campaign/api/gupshup-templates-heads/${templateId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const { heads } = await response.data;
      return heads;
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const sendTestMessage = async (templateId, msgData, type) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const data = new FormData();
      data.append(
        "destination",
        type === "cloud"
          ? msgData.recipientNumber
          : `91${msgData.recipientNumber}`
      );
      delete msgData.recipientNumber;
      if (msgData.templateImg) {
        data.append("image", msgData.templateImg);
        delete msgData.templateImg;
      }
      if (msgData.templateVideo) {
        data.append("video", msgData.templateVideo);
        delete msgData.templateVideo;
      }
      if (msgData.templateDoc) {
        data.append("document", msgData.templateDoc);
        delete msgData.templateDoc;
      }
      data.append("values", JSON.stringify(msgData));
      const response = await axios({
        method: "post",
        url: `/lms_campaign/api/whatsapp/${type}/testmessage/${templateId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: data,
      });
      const { message } = await response.data;
      return message;
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const getExcelHeads = async (excelFile) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const data = new FormData();
      typeof excelFile === "object" && data.append("excel", excelFile);
      const response = await axios({
        method: "post",
        url: `/lms_campaign/api/excel_heads`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: data,
      });
      const res = await response.data;
      return res;
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const getBroadcasts = async (pageNumber, pageSize, dateRange) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axios({
        method: "get",
        url: `/lms_campaign/api/whatsapp/broadcast`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          page_size: pageSize ? pageSize : "",
          date_range: dateRange ? dateRange : "",
          page: pageNumber ? pageNumber : "",
        },
      });
      const { broadcasts } = await response.data;
      return broadcasts;
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const sendBroadcastMessage = async (broadcastFormData) => {
    console.log(broadcastFormData);
    const data = new FormData();
    const createNumbersData = () => {
      data.append("send_by", "numbers");
      data.append("numbers", broadcastFormData.enterNumbers.replace(/\s/g, ""));
      broadcastFormData.templateId &&
        data.append("templateId", broadcastFormData.templateId);
      broadcastFormData.imgTemplate &&
        data.append("image", broadcastFormData.currDynamicValues.templateImg);
    };
    const createExcelData = () => {
      data.append("send_by", "excel");
      // data.append("mobile_no", broadcastFormData.recipientColumn);
      data.append("receiver_column", broadcastFormData.recipientColumn);
      data.append("excel_file", broadcastFormData.excelFileLoc);
      broadcastFormData.templateId &&
        data.append("templateId", broadcastFormData.templateId);
      if (broadcastFormData.currDynamicValues.templateImg) {
        data.append("image", broadcastFormData.currDynamicValues.templateImg);
        delete broadcastFormData.currDynamicValues.templateImg;
      }
      if (broadcastFormData.currDynamicValues.templateVideo) {
        data.append("video", broadcastFormData.currDynamicValues.templateVideo);
        delete broadcastFormData.currDynamicValues.templateVideo;
      }
      if (broadcastFormData.currDynamicValues.templateDoc) {
        data.append(
          "document",
          broadcastFormData.currDynamicValues.templateDoc
        );
        delete broadcastFormData.currDynamicValues.templateDoc;
      }
      broadcastFormData.dynamicTemplate &&
        data.append(
          "values",
          JSON.stringify(broadcastFormData.currDynamicValues)
        );
    };
    broadcastFormData.recipientsSource === "enter numbers"
      ? createNumbersData()
      : broadcastFormData.recipientsSource === "upload excel" &&
        createExcelData();
    //
    data.append("broadcast_name", broadcastFormData.broadcastName);
    data.append("schedule_type", broadcastFormData.scheduleValue);
    broadcastFormData.scheduleValue === "schedule_at" &&
      data.append(
        "schedule_time",
        format(broadcastFormData.scheduleTime, "dd-MM-yyyy pp")
      );

    // Display the key/value pairs
    // for (var pair of data.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axios({
        method: "post",
        url: `/lms_campaign/api/whatsapp/broadcast`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: data,
      });
      const { message } = await response.data;
      return message;
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  return (
    <WhatsAppTemplateContext.Provider
      value={{
        templates: state.templates,
        getTemplateHeads,
        sendTestMessage,
        getExcelHeads,
        getBroadcasts,
        sendBroadcastMessage,
      }}
    >
      {/* {children} */}
      <Outlet />
    </WhatsAppTemplateContext.Provider>
  );
};
