// routes
import { PATH_DASHBOARD } from "src/routes/paths";

// icons
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
// import AccountTreeIcon from "@mui/icons-material/AccountTree";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import PagesIcon from "@mui/icons-material/Pages";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

// components
import SvgIconStyle from "src/components/SvgIconStyle";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  user: getIcon("ic_user"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  googleMyBusiness: getIcon("gmb"),
  whatsapp: getIcon("whatsapp"),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    // subheader: 'general v3.4.0',
    items: [
      { title: "Home", path: "/dashboard/home", icon: <HomeRoundedIcon /> },
      {
        title: "Organisation",
        path: "/dashboard/organisation",
        icon: <CorporateFareIcon />,
        children: [
          { title: "Users", path: PATH_DASHBOARD.organisation.users.list },
          {
            title: "Organisation units",
            path: PATH_DASHBOARD.organisation.units,
          },
          {
            title: "Custom Fields",
            path: PATH_DASHBOARD.organisation.customFields,
          },
        ],
      },
      {
        title: "Social Post",
        path: "/dashboard/social-post",
        icon: <PagesIcon />,
        children: [
          {
            title: "New Post",
            path: PATH_DASHBOARD.socialPost.newPost,
          },
          {
            title: "All Posts",
            path: PATH_DASHBOARD.socialPost.allPosts,
          },
          {
            title: "Analytics",
            path: PATH_DASHBOARD.socialPost.analytics,
          },
          {
            title: "Settings",
            path: PATH_DASHBOARD.socialPost.facebookAndInstagram,
          },
        ],
      },
      // {
      //   title: "User Management",
      //   path: "/dashboard/users",
      //   icon: ICONS.user,
      //   children: [
      //     { title: "Users List", path: "/dashboard/users/users-list" },
      //     { title: "Create User", path: "/dashboard/users/create-user" },
      //   ],
      // },
      // {
      //   title: "Organisation units",
      //   path: "/dashboard/org-units",
      //   icon: <AccountTreeIcon />,
      // },
      {
        title: "Chat App",
        path: "/dashboard/chat-app",
        icon: <MarkUnreadChatAltIcon />,
        children: [
          {
            title: "Chat Report",
            path: "/dashboard/chat-app/report",
          },
          // {
          //   title: "Chat Analytics",
          //   path: "/dashboard/chat-app/analytics",
          // },
          {
            title: "Settings",
            path: "/dashboard/chat-app/settings",
          },
        ],
      },
      {
        title: "WhatsApp",
        path: "/dashboard/gupshup",
        icon: ICONS.whatsapp,
        children: [
          {
            title: "Message Broadcast",
            path: PATH_DASHBOARD.whatsapp.broadcast,
          },
          {
            title: "Broadcasts List",
            path: PATH_DASHBOARD.whatsapp.broadcastList,
          },
          {
            title: "Templates",
            path: PATH_DASHBOARD.whatsapp.templates,
          },
          {
            title: "Broadcasts Journey",
            path: PATH_DASHBOARD.whatsapp.broadcastJourney.list,
          },
          {
            title: "Unsubscribers List",
            path: PATH_DASHBOARD.whatsapp.unsubscribersList,
          },
          {
            title: "Dialogflow",
            path: PATH_DASHBOARD.whatsapp.dialogflowAnalytics,
          },
          {
            title: "Settings",
            path: PATH_DASHBOARD.whatsapp.settings,
          },
        ],
      },
      {
        title: "Google my business",
        path: "/dashboard/google-my-business",
        icon: ICONS.googleMyBusiness,
        children: [
          {
            title: "Reviews",
            path: PATH_DASHBOARD.googleMyBusiness.reviews.root,
          },
          {
            title: "Business messsages",
            children: [
              {
                title: "Locations",
                path: "/dashboard/google-my-business/locations",
              },
              {
                title: "Analytics",
                path: "/dashboard/google-my-business/business-messages-analytics",
              },
            ],
          },
          {
            title: "Geo grid",
            path: "/dashboard/google-my-business/geo-grid",
          },
          {
            // title: "Update Profile",
            title: "Profile Info",
            path: PATH_DASHBOARD.googleMyBusiness.profileInfo,
          },
          {
            // title: "Upload Photos",
            title: "Photos",
            path: PATH_DASHBOARD.googleMyBusiness.uploadPhotos,
          },
          {
            title: "Add Update",
            path: "/dashboard/google-my-business/add-update",
          },
          {
            title: "Services",
            path: "/dashboard/google-my-business/services",
          },
          {
            title: "Settings",
            path: "/dashboard/google-my-business/settings",
            children: [
              {
                title: "General Settings",
                path: "/dashboard/google-my-business/settings/general",
              },
              {
                title: "Manage Locations",
                path: "/dashboard/google-my-business/settings/manage-locations",
              },
              // {
              //   title: "Manage API keys",
              //   path: "/dashboard/google-my-business/settings/manage-api-keys",
              // },
            ],
          },
          // {
          //   title: "Location custom fields",
          //   path: "/dashboard/google-my-business/location-custom-fields",
          // },
          // {
          //   title: "New Post",
          //   path: "/dashboard/google-my-business/new-post",
          // },

          // {
          //   title: "Posts",
          //   path: "/dashboard/google-my-business/posts",
          // },
          // {
          //   title: "Photos",
          //   path: "/dashboard/google-my-business/photos",
          // },
          // {
          //   title: "Products",
          //   path: "/dashboard/google-my-business/products",
          // },
          // {
          //   title: "Services",
          //   path: "/dashboard/google-my-business/services",
          // },
          // {
          //   title: "Insights",
          //   path: "/dashboard/google-my-business/insights",
          // },
          // {
          //   title: "Location POC Details",
          //   path: "/dashboard/google-my-business/location-poc-details",
          // },
        ],
      },
      {
        title: "Assets Manager",
        path: "/dashboard/assets-manager",
        icon: <ManageAccountsIcon />,
        children: [
          {
            title: "URL Manager",
            path: PATH_DASHBOARD.assetsManager.url,
          },
          {
            title: "Media Manager",
            path: PATH_DASHBOARD.assetsManager.media,
          },
          {
            title: "Custom Domain Setup",
            path: PATH_DASHBOARD.assetsManager.domainSetup,
          },
        ],
      },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     {
  //       title: 'user',
  //       path: '/dashboard/user',
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'Four', path: '/dashboard/user/four' },
  //         { title: 'Five', path: '/dashboard/user/five' },
  //         { title: 'Six', path: '/dashboard/user/six' },
  //       ],
  //     },
  //   ],
  // },
];

export default navConfig;
