// react
import { createContext, useEffect, useReducer } from "react";
import { Outlet } from "react-router-dom";
// utils
import axios from "../../utils/axios";
import { isValidToken } from "../../utils/jwt";

// Initial State
const initialState = {
  token: null,
  gmb_webhook: null,
  chat_webhook: null,
  whatsapp_webhook: null,
};

// Action handlers
const handlers = {
  INITIALIZE: (state, action) => {
    const { token, gmb_webhook, chat_webhook, whatsapp_webhook } =
      action.payload;
    return {
      ...state,
      token,
      gmb_webhook,
      chat_webhook,
      whatsapp_webhook,
    };
  },
};

// Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// Create Context
export const ChatTokenContext = createContext();

// Provider Component
export const ChatTokenProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // Auth state
  // const { isAuthenticated, isInitialized } = useAuth();

  const initialize = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const controller = new AbortController();
      if (accessToken && isValidToken(accessToken)) {
        const response = await axios.get(
          "lms_chat/api/chat_token",
          { signal: controller.signal },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        const { token } = await response.data;
        const webhooks = await axios.get(
          "lms_chat/api/webhook_setup",
          { signal: controller.signal },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        const { gmb_webhook, chat_webhook, whatsapp_webhook } =
          await webhooks.data;
        // console.log(webhooks.data);
        //cancel the request
        controller.abort();
        dispatch({
          type: "INITIALIZE",
          payload: {
            token,
            gmb_webhook,
            chat_webhook,
            whatsapp_webhook,
          },
        });
      } else {
        dispatch({
          type: "INITIALIZE",
          payload: {
            token: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "INITIALIZE",
        payload: {
          token: null,
        },
      });
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const updateChatToken = async (chatTokenData) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      await axios({
        method: "post",
        url: `/lms_chat/api/chat_token`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: { chat_token: chatTokenData.chatToken },
      });
      await initialize();
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  return (
    <ChatTokenContext.Provider
      value={{
        token: state.token,
        gmb_webhook: state.gmb_webhook,
        chat_webhook: state.chat_webhook,
        whatsapp_webhook: state.whatsapp_webhook,
        updateChatToken,
      }}
    >
      <Outlet />
      {/* {children} */}
    </ChatTokenContext.Provider>
  );
};
