// react
import { createContext, useCallback, useReducer } from "react";
import { Outlet } from "react-router-dom";

// Initial State
const initialState = {
  form1: { category: "", name: "", languages: [] },
  form2: {
    headerType: "none",
    headerText: "",
    mediaType: "",
    bodyText: "",
    footerText: "",
    buttonType: "none",
    callToActionArr: [
      {
        id: "1",
        typeOfAction: "PHONE_NUMBER",
        buttonText: "",
        urlType: "static",
        phoneWeb: "",
      },
    ],
    quickReplyArr: [{ id: "1", marketingType: "MARKETING", buttonText: "" }],
    bodyVariableCount: [],
    dynamicUrlStatus: false,
  },
  sample: {
    headerText: "",
    headerMediaFile: "",
    bodyText: "",
    dynamicUrl: "",
    bodyVars: {},
    headerVars: {},
    mediaApiResponse: "",
  },
};

// Action handlers
const handlers = {
  // FORM 1 HANDLERS
  UPDATE_CATEGORY: (state, action) => {
    const { category } = action.payload;
    return {
      ...state,
      form1: { ...state.form1, category },
    };
  },
  UPDATE_NAME: (state, action) => {
    const { name } = action.payload;
    return {
      ...state,
      form1: { ...state.form1, name },
    };
  },
  UPDATE_LANGUAGES: (state, action) => {
    const { languages } = action.payload;
    return {
      ...state,
      form1: { ...state.form1, languages },
    };
  },

  // FORM 2 HANDLERS
  UPDATE_HEADER_TYPE: (state, action) => {
    const { headerType } = action.payload;
    return {
      ...state,
      form2: { ...state.form2, headerType },
    };
  },

  UPDATE_HEADER_TEXT: (state, action) => {
    const { headerText } = action.payload;
    return {
      ...state,
      form2: { ...state.form2, headerText },
      sample: { ...state.sample, headerText },
    };
  },

  UPDATE_MEDIA_TYPE: (state, action) => {
    const { mediaType } = action.payload;
    return {
      ...state,
      form2: { ...state.form2, mediaType },
    };
  },
  UPDATE_BODY_TEXT: (state, action) => {
    const { bodyText } = action.payload;
    return {
      ...state,
      form2: { ...state.form2, bodyText },
      sample: { ...state.sample, bodyText },
    };
  },
  UPDATE_FOOTER_TEXT: (state, action) => {
    const { footerText } = action.payload;
    return {
      ...state,
      form2: { ...state.form2, footerText },
    };
  },
  UPDATE_BUTTON_TYPE: (state, action) => {
    const { buttonType } = action.payload;
    return {
      ...state,
      form2: { ...state.form2, buttonType },
    };
  },
  UPDATE_QUICKREPLY_ARR: (state, action) => {
    const { quickReplyArr } = action.payload;
    return {
      ...state,
      form2: { ...state.form2, quickReplyArr },
    };
  },
  UPDATE_CALLTOACTION_ARR: (state, action) => {
    const { callToActionArr } = action.payload;
    return {
      ...state,
      form2: { ...state.form2, callToActionArr },
    };
  },
  UPDATE_BODY_VARIABLE_COUNT: (state, action) => {
    const { bodyVariableCount } = action.payload;
    return {
      ...state,
      form2: { ...state.form2, bodyVariableCount },
    };
  },
  UPDATE_DYNAMIC_URL_STATUS: (state, action) => {
    const { dynamicUrlStatus } = action.payload;
    return {
      ...state,
      form2: { ...state.form2, dynamicUrlStatus },
    };
  },

  // SAMPLE HANDLERS
  UPDATE_SAMPLE_BODYTEXT: (state, action) => {
    const { bodyText } = action.payload;
    return {
      ...state,
      sample: { ...state.sample, bodyText },
    };
  },
  UPDATE_SAMPLE_BODYVARS: (state, action) => {
    const { bodyVars } = action.payload;
    return {
      ...state,
      sample: { ...state.sample, bodyVars },
    };
  },
  UPDATE_SAMPLE_HEADERTEXT: (state, action) => {
    const { headerText } = action.payload;
    return {
      ...state,
      sample: { ...state.sample, headerText },
    };
  },
  UPDATE_SAMPLE_HEADERVARS: (state, action) => {
    const { headerVars } = action.payload;
    return {
      ...state,
      sample: { ...state.sample, headerVars },
    };
  },
  UPDATE_SAMPLE_HEADER_MEDIAFILE: (state, action) => {
    const { headerMediaFile } = action.payload;
    return {
      ...state,
      sample: { ...state.sample, headerMediaFile },
    };
  },
  UPDATE_SAMPLE_DYNAMIC_URL: (state, action) => {
    const { dynamicUrl } = action.payload;
    return {
      ...state,
      sample: { ...state.sample, dynamicUrl },
    };
  },
  UPDATE_MEDIA_API_RESPONSE: (state, action) => {
    const { mediaApiResponse } = action.payload;
    return {
      ...state,
      sample: { ...state.sample, mediaApiResponse },
    };
  },
};

// Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// Create Context
export const CreateTemplateContext = createContext();

// Provider Component
export const CreateTemplateProvider = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  // FORM 1 FUNCTIONS
  const updateCategory = (category) => {
    dispatch({ type: "UPDATE_CATEGORY", payload: { category } });
  };
  const updateName = (name) => {
    dispatch({ type: "UPDATE_NAME", payload: { name } });
  };
  const updateLanguages = (languages) => {
    dispatch({ type: "UPDATE_LANGUAGES", payload: { languages } });
  };

  // FORM 2 FUNCTIONS
  const updateHeaderType = (headerType) => {
    dispatch({ type: "UPDATE_HEADER_TYPE", payload: { headerType } });
  };
  const updateHeaderText = (headerText) => {
    dispatch({ type: "UPDATE_HEADER_TEXT", payload: { headerText } });
  };
  const updateMediaType = (mediaType) => {
    dispatch({ type: "UPDATE_MEDIA_TYPE", payload: { mediaType } });
  };
  const updateBodyText = useCallback((bodyText) => {
    dispatch({ type: "UPDATE_BODY_TEXT", payload: { bodyText } });
  }, []);
  const updateFooterText = useCallback((footerText) => {
    dispatch({ type: "UPDATE_FOOTER_TEXT", payload: { footerText } });
  }, []);
  const updateButtonType = (buttonType) => {
    dispatch({ type: "UPDATE_BUTTON_TYPE", payload: { buttonType } });
  };
  const updateQuickReplyArr = useCallback((quickReplyArr) => {
    dispatch({
      type: "UPDATE_QUICKREPLY_ARR",
      payload: { quickReplyArr },
    });
  }, []);
  const updateCallToActionArr = (callToActionArr) => {
    dispatch({
      type: "UPDATE_CALLTOACTION_ARR",
      payload: { callToActionArr },
    });
  };
  const updateBodyVariableCount = (bodyVariableCount) => {
    dispatch({
      type: "UPDATE_BODY_VARIABLE_COUNT",
      payload: { bodyVariableCount },
    });
  };
  const updateDynamicUrlStatus = (dynamicUrlStatus) => {
    dispatch({
      type: "UPDATE_DYNAMIC_URL_STATUS",
      payload: { dynamicUrlStatus },
    });
  };

  // SAMPLE FUNCTIONS
  const updateSampleBodyText = useCallback((bodyText) => {
    dispatch({
      type: "UPDATE_SAMPLE_BODYTEXT",
      payload: { bodyText },
    });
  }, []);
  const updateSampleBodyVars = (bodyVars) => {
    dispatch({
      type: "UPDATE_SAMPLE_BODYVARS",
      payload: { bodyVars },
    });
  };
  const updateSampleHeaderText = (headerText) => {
    dispatch({
      type: "UPDATE_SAMPLE_HEADERTEXT",
      payload: { headerText },
    });
  };
  const updateSampleHeaderVars = (headerVars) => {
    dispatch({
      type: "UPDATE_SAMPLE_HEADERVARS",
      payload: { headerVars },
    });
  };
  const updateSampleHeaderMediaFile = (headerMediaFile) => {
    dispatch({
      type: "UPDATE_SAMPLE_HEADER_MEDIAFILE",
      payload: { headerMediaFile },
    });
  };
  const updateSampleDynamicUrl = (dynamicUrl) => {
    dispatch({
      type: "UPDATE_SAMPLE_DYNAMIC_URL",
      payload: { dynamicUrl },
    });
  };
  const updateMediaApiResponse = (mediaApiResponse) => {
    dispatch({
      type: "UPDATE_MEDIA_API_RESPONSE",
      payload: { mediaApiResponse },
    });
  };

  return (
    <CreateTemplateContext.Provider
      value={{
        form1: state.form1,
        form2: state.form2,
        sample: state.sample,
        updateCategory,
        updateName,
        updateLanguages,
        updateHeaderType,
        updateHeaderText,
        updateMediaType,
        updateBodyText,
        updateButtonType,
        updateFooterText,
        updateQuickReplyArr,
        updateCallToActionArr,
        updateBodyVariableCount,
        updateSampleBodyText,
        updateSampleBodyVars,
        updateSampleHeaderText,
        updateSampleHeaderVars,
        updateSampleHeaderMediaFile,
        updateSampleDynamicUrl,
        updateDynamicUrlStatus,
        updateMediaApiResponse,
      }}
    >
      <Outlet />
    </CreateTemplateContext.Provider>
  );
};
