import axios from "src/utils/axios";
import { isValidToken } from "src/utils/jwt";

export default async function makeNewPost(postData) {
  try {
    const accessToken = localStorage.getItem("accessToken");
    if (isValidToken(accessToken)) {
      const data = new FormData();
      data.append("post_name", postData.postName);
      data.append("message", postData.message);
      typeof postData.image === "object" &&
        data.append("image", postData.image);
      if (postData.google) {
        data.append("google", JSON.stringify(postData.google));
      }
      if (postData.facebook) {
        data.append("facebook", JSON.stringify(postData.facebook));
      }
      if (postData.instagram) {
        data.append("instagram", JSON.stringify(postData.instagram));
      }
      if (postData.linkedin) {
        data.append("linkedin", JSON.stringify(postData.linkedin));
      }
      data.append("schedule_type", postData.scheduleType);
      postData.scheduleTime &&
        data.append("schedule_time", postData.scheduleTime);
      // Display the key/value pairs
      //   for (var pair of data.entries()) {
      //     console.log(pair[0] + ", " + pair[1]);
      //   }
      const response = await axios({
        method: "post",
        url: `/lms_user/api/social/post`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: data,
      });
      const res = await response.data;
      return res;
    }
  } catch (error) {
    console.log(Object.values(error.errors));
    // throw new Error(Object.values(error.errors));
  }
}
