// react
import { createContext, useEffect, useReducer } from "react";
import { Outlet } from "react-router-dom";
// utils
import axios from "../../utils/axios";
import { isValidToken } from "../../utils/jwt";
// Initial State
const initialState = {
  login_url: null,
  accounts: [],
};

// Action handlers
const handlers = {
  INITIALIZE: (state, action) => {
    const { login_url, accounts } = action.payload;
    return {
      ...state,
      login_url,
      accounts,
    };
  },
};

// Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// Create Context
export const GmbLocAccContext = createContext();

// Provider Component
export const GmbLocAccProvider = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const initialize = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const controller = new AbortController();
      if (accessToken && isValidToken(accessToken)) {
        const response = await axios({
          method: "get",
          url: `/lms_user/api/gmb/account_setup`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const { login_url, accounts } = await response.data;
        // cancel the request
        controller.abort();
        dispatch({
          type: "INITIALIZE",
          payload: {
            login_url,
            accounts,
          },
        });
      } else {
        dispatch({
          type: "INITIALIZE",
          payload: {
            locations: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "INITIALIZE",
        payload: {
          locations: null,
        },
      });
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const setupGmbAccount = async (setupData) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      await axios({
        method: "post",
        url: `/lms_user/api/gmb/account_setup_callback`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: setupData,
      });
      await initialize();
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const monitorLocations = async (accountId, locationId, monitor) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const data = new FormData();
      data.append("account_id", accountId);
      data.append("location_ids", locationId);
      data.append("monitor", monitor);
      await axios({
        method: "post",
        url: `/lms_user/api/gmb/monitor_location`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: data,
      });
      await initialize();
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const deleteAccount = async (accountId) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const data = new FormData();
      data.append("account_id", accountId);
      await axios({
        method: "post",
        url: `/lms_user/api/gmb/remove_account`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: data,
      });
      await initialize();
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const assignOrgNode = async (nodeId, locationId) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const data = new FormData();
      data.append("node_id", nodeId);
      data.append("location_ids", locationId);
      await axios({
        method: "post",
        url: `/lms_user/api/gmb/assign_node`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: data,
      });
      await initialize();
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  return (
    <GmbLocAccContext.Provider
      value={{
        accounts: state.accounts,
        loginUrl: state.login_url,
        setupGmbAccount,
        monitorLocations,
        deleteAccount,
        assignOrgNode,
      }}
    >
      <Outlet />
      {/* {children} */}
    </GmbLocAccContext.Provider>
  );
};
