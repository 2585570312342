// utils
import axios from "src/utils/axios";
import { isValidToken } from "src/utils/jwt";

export default async function updateIntent(data, responseType, intentId) {
  let formData = new FormData();

  formData.append("intent_name", data.intentName);

  formData.append("training_phrases", data.trainingPhrases);

  formData.append("response_type", responseType);

  if (responseType === "text") {
    formData.append("response_text", data.responseText);
  }

  if (data.file.path) {
    if (responseType === "image") {
      formData.append("file", data.file);
    }

    if (responseType === "pdf") {
      formData.append("file", data.file);
    }
  }

  try {
    const accessToken = localStorage.getItem("accessToken");
    if (isValidToken(accessToken)) {
      const response = await axios({
        method: "post",
        url: `lms_chat/api/google/dialogflow/intents/${intentId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
      });
      const res = await response.data;
      return res;
    }
  } catch (error) {
    throw new Error(Object.values(error.errors));
  }
}
